@import 'style-constants.scss';

.select-tech-stack-wrapper {
    .technologies-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-top: 14px;
        margin-bottom: -14px;

        & > .technology-container {
            margin-right: 12px;
            margin-bottom: 14px;
        }

        .technology-container {
            display: flex;
            align-items: center;
            padding: 8px 10px;
            border: 1px solid #DDDDDD;
            border-radius: 4px;
        }

        .technology-name {
            font-size: 16px;
            font-weight: 300;
            line-height: normal;
            letter-spacing: 0.29px;
            color: $grey-dark-text;
        }

        .delete-technology-icon {
            margin-left: 10px;
            width: 15px;
            height: 15px;
            color: $grey-dark;
            cursor: pointer;

            &:hover {
                color: $primary-color;
            }
        }
    }

    .add-new-skill-option {
        cursor: pointer;
    }

    .add-skill-option {
        color: $grey-dark;
    }

    .search-skills-loader {
        display: flex;
        align-items: center;
        justify-content: center;

        .MuiCircularProgress-colorPrimary {
            color: $primary-color-700;
        }
    }

    .MuiAutocomplete-popperDisablePortal {
        position: fixed;
    }
}