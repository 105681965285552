@import 'style-constants.scss';

.assign-admin-dialog-container {    
    .MuiDialog-paper {
        width: 490px;
        min-height: 212px;
        max-height: 515px;
        padding: 19px 41px 25px 41px;
        margin-bottom: 20%;
        overflow: visible;
    }        

    .dialog-title {
        width: 417px;
        display: flex;
        justify-content: space-between;
        color: $grey-dark;
        padding: 0;

        p {
            font-size: 17px;
            letter-spacing: 0.11px;
        }

        .dialog-title-text {
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    .dialog-content {
        padding: 0;
        overflow-y: visible;
        max-height: 412px;
    }
}
