@import 'style-constants.scss';

.form-validation-requirement {
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-top: 11px;

    font-size: $smallest;
    line-height: 16px;
    letter-spacing: 0.11px;
    color: rgba($purple-darker-light, 0.6);

    .icon-wrapper {
        display: flex;
        min-width: 16px;
        min-height: 16px;
    }

    svg {
        font-size: $medium;
        color: rgba($purple-dark-light, 0.6);
    }

    &.met {
        color: $purple-dark-light;

        svg {
            color: $purple-dark-light;
        }
    }

    &.not-met {
        color: rgba($red, 0.6);

        svg {
            color: rgba($red, 0.6);
        }
    }

    @include profile-creation-mobile {
        font-size: $tiniest;
        line-height: 12px;
        letter-spacing: 0.08px;
    }
}
