@import 'style-constants.scss';

.application-status-banner-container {
    padding: 20px 40px;
    display: grid;
    grid-auto-flow: row;
    gap: 10px;
    position: relative;
    margin-bottom: 20px;
    border-radius: 18px;

    .status-text {
        color: $purple-dark;
        text-align: left;
        font-size: $medium;
        letter-spacing: 0.11px;
    }

    .steps-list-container {
        display: grid;
        grid-auto-flow: column;
        gap: 2px;
    }

    .MuiLinearProgress-colorPrimary {
        background-color: $grey-progress;
    }

    .MuiLinearProgress-barColorPrimary {
        background-color: $medium-dark-shade-blue;
    }

    .custom-progress {
        height: 7px;
        border-radius: 2px;
    }

    // SVG animations and styles below

    @keyframes breathing {
        0% {
            -webkit-transform: scale(0.9);
            -ms-transform: scale(0.9);
            transform: scale(0.9);
        }
    
        33% {
            -webkit-transform: scale(1);
            -ms-transform: scale(1);
            transform: scale(1);
        }
    
        100% {
            -webkit-transform: scale(0.9);
            -ms-transform: scale(0.9);
            transform: scale(0.9);
        }
    }
    
    @keyframes loader {
        0% {
            opacity: 1;
        }
    
        100% {
            opacity: 0;
        }
    }
    
    @keyframes blink {
        0% {
            opacity: 1;
        }
    
        50% {
            opacity: 0;
        }
    
        100% {
            opacity: 1;
        }
    }
    
    @keyframes moveUpAndDown {
        0%, 100% {
            top: -20px;
        }
    
        50% {
            top: -10px;
        }
    }
    
    @keyframes spin {
        0% {
            transform-origin: center;
            transform: rotate(0deg) translate(-233.536px, -231.874px);
        }
    
        100% {
            transform-origin: center;
            transform: rotate(360deg) translate(-233.536px, -231.874px);
        }
    }

    .check,
    .clock,
    .loader,
    .messages {
        align-self: flex-end;
        position: absolute;
        right: 20px;
        top: -20%;
    }
    
    .check .checkbox {
        transform-origin: center;
        animation: breathing 3s ease-out infinite;
    }
    
    .messages {
        animation: moveUpAndDown 3s linear infinite;
    }
    
    .clock path:nth-child(7) {
        animation: spin 60s linear infinite;
    }
    
    .loader path {
        animation: loader 0.7s linear infinite;
        animation-fill-mode: forwards;
    }
    
    .loader path:nth-child(1) {
        animation-delay: -0.6s;
    }
    
    .loader path:nth-child(2) {
        animation-delay: -0.5s;
    }
    
    .loader path:nth-child(3) {
        animation-delay: -0.4s;
    }
    
    .loader path:nth-child(4) {
        animation-delay: -0.3s;
    }
    
    .loader path:nth-child(5) {
        animation-delay: -0.2s;
    }
    
    .loader path:nth-child(6) {
        animation-delay: -0.1s;
    }
    
    .loader path:nth-child(7) {
        animation-delay: 0s;
    }    
}
