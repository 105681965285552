@import "style-constants.scss";

.profile-creation-layout-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;  
    max-height: -webkit-fill-available;
    overflow-y: auto;
    background-color: $indigo-dark;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
        width: 0px;
    }

    .profile-creation-layout-body-wrapper {
        width: 100%;
        position: relative;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        .circles-wrapper {
            position: absolute;
            width: 1920px;
            height: 100%;
            margin: 0 auto;
            overflow: hidden;
        }

        .circle {
            border-radius: 50%;
            position: absolute;

            &.big {
                width: 435px;
                height: 435px;
                top: 0px;
                left: 1054px;
            }

            &.medium {
                width: 346px;
                height: 346px;
                top: 818px;
                left: 1135px;
                background: transparent linear-gradient(180deg, $teal 0%, $teal-dark 100%) 0% 0% no-repeat padding-box;
                opacity: 0.58;
            }

            &.small {
                width: 168px;
                height: 168px;
                top: 611px;
                left: 546px;
            }

            &.big,
            &.small {
                background: transparent linear-gradient(180deg, $indigo 0%, $indigo-darker 100%) 0% 0% no-repeat padding-box;
            }
        }
    }

    .profile-creation-layout-content {
        width: 100%;
        position: relative;
        padding: 25px 0px;
    }

    .MuiCircularProgress-root {
        width: 29px !important;
        height: 29px !important;
    }

    @include profile-creation-mobile {
        background-color: $white;

        .profile-creation-layout-body-wrapper {
            align-items: flex-start;
        }

        .circles-wrapper {
            display: none;
        }

        .MuiCircularProgress-root {
            width: 16px !important;
            height: 16px !important;
        }
    }
}
