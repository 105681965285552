.edit-cv-wrapper {
    padding: 31px 0;

    .edit-container {
        max-width: 710px;
        margin-left: auto;
        margin-right: auto;
    }

    .flex-header {
        display: flex;
        justify-content: space-between;
    }
    .paper-element-description, .paper-element-content {
        padding: 0px;
        box-shadow: none;
    }

    .profile-type-field {
        width: 100%;
        margin-bottom: 35px;
        
        .profile-type-dropdown {
            width: 100%;
        }
    }
}
