@import 'style-constants.scss';

.dashboard-content-wrapper {
    .client-dashboard-inner-content-wrapper {
        .client-opportunities-wrapper {
            .client-opportunities-container {
                .section {
                    margin-bottom: 0;
                }
            }
        }
    }

    .client-dashboard-inner-content-wrapper {
        .client-projects-container {
            .client-project-container{
                margin-bottom: 72px;
            }

            .client-project-container:last-child{
                margin-bottom: 60px;
            }
        }
    }

    .client-dashboard-inner-content-wrapper:not(:last-child) {
        margin-bottom: 30px;
    }
    a {
        text-decoration: none;
    }
}
