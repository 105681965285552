@import 'style-constants.scss';

.form-input-field-wrapper {
    &:not(:first-child) {
        margin-top: 15px;
    }

    &.smaller {
        .field-input .MuiOutlinedInput-input::placeholder {
            font-size: $smallest;
            letter-spacing: 0.11px;
        }
    }

    .field-label {
        font-weight: bold;
        font-size: $medium-title;
        line-height: 22px;
        letter-spacing: 0.17px;
        color: $purple-darker-light;
        text-transform: uppercase;
        margin-bottom: 5px;
    }

    .field-input {
        .MuiOutlinedInput-root {
            color: rgba($purple-dark-lighter, 1);
            border-radius: 30px;

            &.Mui-error {
                .MuiOutlinedInput-notchedOutline {
                    border-color: $red;
                }

                .MuiOutlinedInput-input::placeholder {
                    color: $red;
                }
            }

            &:not(.Mui-error) .MuiOutlinedInput-notchedOutline {
                border-color: $medium-light-shade-blue;
            }
        }

        .MuiOutlinedInput-input {
            padding: 16px 16px 16px 30px;

            &::placeholder {
                font-size: $small;
                font-weight: 300;
                font-style: italic;
                letter-spacing: 0.13px;
                opacity: 1;
                text-align: left;
            }
        }

        .MuiOutlinedInput-adornedEnd {
            padding-right: 16px;
        }
    }

    @include profile-creation-mobile {
        .field-label {
            font-size: $medium;
            line-height: 19px;
            letter-spacing: 0.15px;
            margin-bottom: 3px;
        }

        .field-input {
            .MuiOutlinedInput-input {
                padding-top: 12px;
                padding-bottom: 12px;

                &::placeholder {
                    font-size: $smallest;
                    letter-spacing: 0.11px;
                }
            }
        }

        &.smaller {
            .field-input {
                .MuiOutlinedInput-input {
                    padding: 12px 16px;
                }
            }
        }
    }
}
