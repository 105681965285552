@import 'style-constants.scss';

.common-formik-dialog {
    position: relative;

    ::-webkit-scrollbar {
        width: 3px;
    }

    ::-webkit-scrollbar-thumb,
    ::-webkit-scrollbar-thumb:hover {
        background: $purple-light;
    }

    .MuiDialog-paper {
        padding: 30px 40px 0px;
    }

    .dialog-title {
        padding: 0;
        padding-right: 30px;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-text {
            color: $grey-dark;
            font-size: 16px;
            font-weight: 500;
        }

        .dialog-close-btn {
            position: absolute;
            top: 16px;
            right: 24px;
        }
    }

    .dialog-content {
        padding: 8px 0px 0px;
        overflow: unset;
    }

    @media (max-width: $mobile) {
        .MuiDialog-container {
            height: unset;
        }

        .MuiDialog-paper {
            padding: 30px 40px;
            width: 100%;
            margin: 0;
            height: 100vh;
        }

        .MuiDialog-paperWidthSm {
            max-width: unset;
        }

        .MuiDialogContent-root {
            flex: unset;
        }

        .dialog-actions-container {
            display: flex;
            justify-content: center;
        }

        .dialog-title {
            .dialog-title-text {
                color: $purple-dark;
                font-size: 18px;
            }
        }
    }

    .dialog-actions-container {
        position: sticky;
        bottom: 0;
        margin-top: 12px;
        z-index: 1;
        padding: 20px 0px 30px;
        background-color: $white;

        .dialog-save-btn.loading {
            .MuiButton-label {
                justify-items: center;

                span {
                    visibility: hidden;
                }
            }

            .button-loader {
                position: absolute;
            }
        }
    }

    .MuiSelect-icon {
        right: 16px;
        color: $grey-dark;
    }

    .form {
        display: grid;
        gap: 20px;

        .invalid-field-message {
            padding-left: 16px;
            padding-top: 4px;
            font-size: 12px;
            letter-spacing: 0.4px;
            color: $red-darker;
        }

        .MuiFormControl-root {
            .MuiInput-underline:after {
                border-bottom-color: $primary-color-700;
            }

            label {
                color: $grey-dark;

                &.Mui-disabled {
                    opacity: 0.71;
                }
            }

            label.Mui-focused,
            &:hover label.MuiInputLabel-shrink {
                color: $primary-color-900;

                &.Mui-error {
                    color: $red-darker;
                }

                font-weight: 500;
            }

            .MuiOutlinedInput-root.Mui-disabled {
                opacity: 0.71;
            }

            .MuiOutlinedInput-root:not(.Mui-error):not(.Mui-disabled) {
                .MuiOutlinedInput-input {
                    color: $blue-darkest;
                }

                fieldset {
                    border-color: $grey-lighter;
                    border-width: 1px;
                }

                &:hover,
                &.Mui-focused {
                    fieldset {
                        border-color: $primary-color-700;
                    }

                    span {
                        color: $primary-color-900;
                        font-weight: 500;
                    }

                    svg {
                        color: $primary-color-700;
                    }
                }
            }

            .MuiOutlinedInput-root.Mui-error:not(.Mui-disabled) {
                fieldset {
                    border-color: $red-darker;
                    border-width: 1px;
                }

                &:hover,
                &.Mui-focused {
                    span {
                        color: $red-darker;
                        font-weight: 500;
                    }

                    svg {
                        color: $red-darker;
                    }
                }
            }
        }
    }
}

.common-formik-dialog.sm {
    .MuiDialog-paper {
        padding: 30px 40px 0px;
        width: 462px;
    }
}

.common-formik-dialog.md {
    .MuiDialog-paper {
        padding: 30px 40px 0px;
        width: $paper-element-m-plus;
    }
}
