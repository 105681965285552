@import 'style-constants.scss';

.job-card-wrapper {
    background: $white 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px $grey-darker-button;
    border-radius: 18px;

    .card-header {
        display: flex;
        justify-content: flex-end;

        .status {
            padding: 8px 15px;
            border-bottom-left-radius: 4px;
            border-top-right-radius: 4px;
            text-align: center;
            font-size: $medium;
            line-height: 17px;
            font-weight: 400;
            letter-spacing: 0.017em;

            &.pending-review,
            &.pending-admin-review,
            &.interview-stage,
            &.not-vetted {
                background-color: rgba($grey-dark, 0.1);
                color: $grey-dark;
            }

            &.hired {
                background-color: rgba($secondary-color-300, 0.1);
                color: $secondary-color-300;
            }

            &.rejected {
                background-color: rgba($red-darker, 0.1);
                color: $red-darker;
            }
        }
    }

    .card-title {
        font-weight: 800;
        font-size: $medium-title;
        letter-spacing: 0.61px;
        color: $purple-dark;
    }

    .card-sub-title {
        font-size: $medium;
        letter-spacing: 0.28px;
        color: $purple-dark;
    }

    .card-content {
        padding: 18px 40px 50px 40px;
        display: grid;
        grid-auto-flow: row;
        gap: 10px;

        .row {
            display: grid;
            grid-auto-flow: column;
            justify-content: space-between;

            &.grid-wrapper {
                justify-content: flex-start;
                gap: 10px;
            }
        }
    }

    .date-wrapper {
        display: grid;
        grid-auto-flow: column;
        gap: 8px;
        align-items: center;

        .date-value {
            font-size: $medium;
            letter-spacing: 0.15px;
            color: $purple-dark;
        }
    }

    .job-info-toggle {
        display: grid;
        grid-auto-flow: column;
        gap: 8px;
        min-width: 200px;
        align-items: center;

        p {
            text-decoration: underline;
            font-size: $medium;
            letter-spacing: 0.28px;
            color: $purple-dark;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .section-divider {
        margin: 20px 0px;
        background-color: $grey-light-amount;
    }

    .feedback-wrapper {
        .feedback-header {
            width: fit-content;
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            gap: 10px;
            margin-bottom: 8px;

            h4 {
                font-size: $medium;
                font-weight: 500;
                line-height: 21px;
                letter-spacing: 0.28px;
                color: $purple-dark;
            }
        }

        .feedback-body {
            font-size: $medium;
            line-height: 21px;
            letter-spacing: 0.28px;
            color: $purple-dark;
            margin-left: 23.1px;
            white-space: pre-wrap;
            word-wrap: break-word;
        }
    }

    .full-job-details-body {
        padding: 20.15px 41.37px 31.26px 40.25px;
        background-color: $white;
        border-radius: 4px;

        .job-section {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            p {
                margin-bottom: 15px;
            }

            .job-section-title {
                color: $purple-dark;
                font-size: 18px;
                font-weight: 600;
            }

            .job-section-text {
                color: $purple-dark;
                font-size: 16px;
                
                white-space: pre-line;
            }

            .skills-container {
                display: flex;
                flex-wrap: wrap;

                .skill-container {
                    align-items: center;
                    padding: 7px 14px;
                    border: 1px solid #dddddd;
                    border-radius: 4px;
                    margin-bottom: 10px;
                    margin-right: 15px;
                    background-color: $white;
                    cursor: pointer;

                    .skill {
                        color: $grey-dark-text;
                        width: calc(100% - 23px);
                        display: inline;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}