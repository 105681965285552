.assign-admin-form {
    max-height: 312px;
    overflow: visible;
    
    .people-results-list::-webkit-scrollbar {
        display: none;
    }
    
    .people-results-list {        
        max-height: 250px;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .dialog-actions-wrapper {
        display: flex;
        justify-content: flex-end;

        .dialog-actions-container {
            display: flex;
            justify-content: space-between;
            width: 188px;
        }
    }
}

.assign-admin-form::-webkit-scrollbar {
    display: none;
}
    
.example {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
