@import "style-constants.scss";

.candidate-profile-page {
    min-height: 100vh;

    .improve-cv {
        margin-top: 2rem;
    }

    .candidate-cv {
        display: flex;
        flex-direction: column;
        margin: 1.67rem auto auto;
        border-radius: 18px;

        .cv-completion-page {
            margin: auto;
            box-shadow: 0px 1px 3px $grey-darker-button;
            border-radius: 18px;
        }
    }

    .raw-cv-profile-content.paper-element-container {
        width: 100%;
        max-width: 100%;

        .import-pdf-container {
            width: 100%;
        }
    }
}
