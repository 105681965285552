@import "style-constants.scss";

.add-department-form {
  .add-department-form-field {
    .label {
      color: $primary-color-500;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .name {
    margin: 15px 0px;
  }

  .admins {
    margin-bottom: 15px;
  }

  .members {
    margin-bottom: 25px;
  }

  .result-list-item {
    border-bottom: 1px solid $grey-light-medium;

    .employee-info {
      span {
        color: #0010ac;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .actions-wrapper {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 30px 40px;

    .actions-container {
      display: flex;
      justify-content: space-between;
      width: 170px;
    }
  }
}
