@import 'style-constants.scss';

.register-footer {
    max-width: 570px;
    margin-top: 35px;
    padding: 0 70px;

    .logo-wrapper {
        display: flex;

        img {
            max-width: 100%;
            max-height: 100%;
        }

        .logo-mobile {
            display: none;
        }
    }

    @include profile-creation-mobile {
        margin-top: 15px;
        max-width: 400px;

        .logo-wrapper {
            .logo {
                display: none;
            }

            .logo-mobile {
                display: block;
            }
        }
    }
}
