@import "style-constants.scss";

.paper-element-container {
    padding: 32px 38px;
    margin-bottom: 31px;
    position: relative;        

    @media screen and (max-width: $mobile) {
        &.max {
            margin: 0;
            width: 100%;
        }
    }


    .paper-element-heading {
        margin-bottom: 5px;

        .paper-element-title {
            color: $purple-dark;
            font-size: $medium-title;
            padding-bottom: 20px;
        }

        svg {
            &:hover {
                cursor: pointer;
            }
        }
    }

    .paper-element-description {
        color: $purple-dark;
        font-size: $medium;
    }

    .info-text {
        padding: 18px 21px;
        max-width: 250px;
        position: absolute;
        right: -200px;
        top: -100px;
        background: $white 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px #82828224;
        opacity: 1;

        font: normal normal normal $small Montserrat;
        line-height: 22px;
        letter-spacing: 0.25px;
        color: $grey-dark-text;
        opacity: 1;
    }

    &.sm {
        margin: 31px auto;
        max-width: $paper-element-sm;
        margin-bottom: 10px;
    }

    &.m {
        margin: 31px auto;
        max-width: $paper-element-m;
    }

    &.m-plus {
        margin: 31px auto;
        max-width: $paper-element-m-plus;
    }   

    &.m-nopadding {
        margin: 31px auto;
        max-width: $paper-element-m-nopadding;
    }
    
}
