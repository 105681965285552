@import "style-constants.scss";

.experience-selection-wrapper {
    display: flex;
    flex-flow: column;

    .primary-button {
        width: fit-content;
        margin: 0 auto auto;
    }

    .tech-stack-options-wrapper {
        margin-top: 20px;
        padding: 30px 40px;
        background-color: $white;
        box-shadow: 0px 0px 10px #79797929;
        border-radius: 4px;
        width: 100%;

        .paper-content-flex {
            display: grid;
            gap: 25px;
        }

        .tech-stack-options-title {
            .paper-element-title {
                font-size: 16px;
                font-weight: 100;
                letter-spacing: 0;
                color: $purple-dark;
            }
        }

        .tech-stack-options {
            display: flex;
            margin-bottom: -12px;
            flex-wrap: wrap;

            &.transition-error {
                .single-technology-wrapper {
                    border: 1px solid $red-darker;
                    color: $red-darker;
                }
            }

            &.error,
            &.animation-error {
                .single-technology-wrapper {
                    animation: fadeout-technology-wrapper 3s ease-in-out forwards;
                }

                .jobs-count-badge {
                    animation: fadeout-jobs-badge 3s ease-in-out forwards;
                }
            }
        }

        .single-technology-wrapper {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            margin-right: 12px;
            padding: 5px 10px;
            border: 1px solid #dddddd;
            border-radius: 4px;
            background-color: $white;
            color: $purple-dark;
            cursor: pointer;

            .technology-name {
                font-size: 16px;
                font-weight: 300;
                letter-spacing: 0.29px;

                &::before {
                    display: block;
                    content: attr(data-content);
                    font-weight: 400;
                    height: 0px;
                    color: transparent;
                    overflow: hidden;
                    visibility: hidden;
                }
            }

            .jobs-count-badge {
                border: 1px solid #dddddd;
                border-radius: 4px;
                background-color: #f6f6f6;
                padding: 4px;
                margin-left: 7px;

                span {
                    font-size: 14px;
                    font-weight: 300;
                }
            }

            &.with-tech-stack {
                &.selected {
                    color: $white;
                    border-color: $secondary-color-300;
                    background-color: $secondary-color-300;

                    .technology-name {
                        font-weight: 400;
                    }

                    .jobs-count-badge {
                        border: 1px solid #11a775;
                        background-color: #11a775;
                    }
                }

                &:hover:not(.selected) {
                    border: 1px solid rgba($secondary-color-300, 0.08);
                    background-color: rgba($secondary-color-300, 0.08);
                    background-clip: padding-box;

                    .jobs-count-badge {
                        border: 1px solid rgba(#11a775, 0.1);
                        background-color: rgba(#11a775, 0.1);
                        background-clip: padding-box;
                    }
                }
            }

            &.without-tech-stack {
                opacity: 0.5;
            }
        }
    }

    .switcher-wrapper {
        position: relative;
        margin-top: 25px;

        .custom-switch-control {
            flex-direction: row;
            margin-right: 0px;

            .custom-switch {
                height: 17px;
                margin-left: 0px;
                margin-right: 6px;
            }

            &:not(:first-child) {
                margin-left: 16px;
            }

            .MuiSwitch-switchBase {
                padding: 0;
                margin: 3px;
                &.Mui-checked {
                    transform: translateX(11px);
                }
            }

            .MuiSwitch-thumb {
                width: 11px;
                height: 11px;
            }
        }

        .MuiFormControlLabel-label {
            font-size: 14px;
            font-weight: 300;
            line-height: 19px;
            letter-spacing: 0.25px;
            color: $medium-dark-shade-blue;
        }

        .switcher-tooltip {
            position: absolute;
            z-index: 1;
            width: 270px;
            padding: 20px;
            margin: -135px 0px 0px 151px;
            background-color: white;
            box-shadow: 0px 1px 3px #82828224;
            border-radius: 4px;
            font-size: 14px;
            letter-spacing: 0.25px;
            color: #666;
        }
    }

    .invalid-field-wrapper {
        display: flex;
        align-items: center;
        color: $red-darker;
        padding-top: 11px;

        .error-icon {
            width: 22px;
            height: 22px;
            margin-right: 9px;
        }

        span {
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.25px;
        }
    }
}

@keyframes fadeout-technology-wrapper {
    0% {
        border: 1px solid $red-darker;
        background-color: $white;
    }
    99.9% {
        background-color: $white;
        border: 1px solid #dddddd;
    }
}

@keyframes fadeout-jobs-badge {
    0%,
    99.9% {
        color: $purple-dark;
        border: 1px solid #dddddd;
        background-color: #f6f6f6;
    }
}
