@import 'style-constants.scss';

.auth-card-wrapper {
    .auth-card-content {
        width: 100%;
        max-width: 570px;
        background-color: transparent;
        margin: 0 auto;

        .auth-card-header {
            padding: 35px 0px 25px;
            border-top-left-radius: 40px;
            border-top-right-radius: 40px;
            background-color: $purple-dark;
            display: grid;
            gap: 10px;
            justify-content: center;
            position: relative;

            .auth-card-media {
                display: flex;
                justify-content: center;
                width: auto;
                height: 60px;

                svg {
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            .auth-card-title {
                font-size: $larger-title;
                line-height: 29px;
                letter-spacing: 0.23px;
                color: $white;
                text-align: center;
            }
        }

        .auth-card-form-wrapper {
            padding: 30px 95px;
            border-bottom-left-radius: 40px;
            border-bottom-right-radius: 40px;
            background-color: $white;
            margin: 0 auto;
        }
    }

    @include profile-creation-mobile {
        .auth-card-content {
            max-width: 400px;

            .auth-card-header {
                background-color: $white;

                .auth-card-media {
                    height: 45px;
                }

                .auth-card-title {
                    color: $medium-shade-blue;
                    font-size: $smaller;
                    line-height: 16px;
                    letter-spacing: 0.12px;
                }
            }

            .auth-card-form-wrapper {
                padding: 30px;
            }
        }
    }
}