@import 'style-constants.scss';

.application-process-banner-wrapper {
    padding: 29.34px 48.75px;
    background-color: $primary-color-light;
    box-shadow: 0px 1px 3px $grey-medium-shadow;
    border-radius: 18px;

    .welcome-back-wrapper {
        display: grid;
        gap: 15px;
        margin-bottom: 30px;
        padding: 0px 80px;
        justify-content: center;
        text-align: center;

        .welcome-back-title {
            font-size: $medium-title;
            font-weight: 500;
            letter-spacing: 0.31px;
            color: $purple-dark;
        }

        .welcome-back-text {
            font-size: $small;
            line-height: 22px;
            letter-spacing: 0.25px;
            color: $medium-dark-shade-blue;
        }

        .welcome-back-update-btn {
            width: fit-content;
            margin: 0 auto;
        }
    }

    .application-process-banner-title {
        margin-bottom: 21px;
        color: $purple-dark;
        font-size: $medium;
        font-weight: 500;
        letter-spacing: 0.27px;
        text-align: center;
        display: grid;
        grid-auto-flow: column;
        gap: 5px;
        justify-content: center;
        align-items: center;
        line-height: 24px;
    }

    .application-process-banner-steps-container {
        display: grid;
        grid-auto-flow: column;
    }

    .info-icon {
        width: 13.5px;
        height: 13.5px;

        path {
            fill: $purple-dark;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .arrow-icon {
        margin-top: 5px;

        &.done path {
            opacity: 1;
        }
    }

    .step-container {
        &.current-done {
            .step-box-wrapper .step-box {
                background-color: $purple-dark;
            }
        }

        &.previous-done:not(.current-done) {
            .step-box-wrapper .step-box {
                border-color: transparent;
                background-color: rgba($purple-dark, 0.1);
            }

            .step-text-wrapper .step-text{
                font-weight: 500;
            }
        }

        .step-box-wrapper {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .step-box {
                width: 20.5px;
                height: 21.5px;
                margin: 0 auto;
                border: 1px solid $purple-dark;
                border-radius: 4px;
                background-color: $white;
                box-shadow: 0px 1px 3px $grey-darker-button;
                display: flex;
                justify-content: center;
                align-content: center;
                color: $purple-dark;

                .step-box-content {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .checkmark-icon {
                    width: 12px;
                    height: 12px;

                    path {
                        stroke: $white;
                        fill: $white;
                    }
                }
            }
        }

        .step-text-wrapper {
            max-width: 95px;
            margin: 0 auto;

            .step-text {
                font-size: $medium;
                font-weight: 300;
                line-height: 21px;
                letter-spacing: 0.28px;
                color: $purple-dark;
                text-align: center;
            }
        }
    }
}
