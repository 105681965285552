@import "style-constants.scss";

.client-job-opportunities-banners-wrapper {
    .client-job-opportunities-banners-container {
        display: flex;
        gap: 30px;

        .section {
            width: 50%;
        }

        .section-inner {
            position: relative;
            display: flex;
            flex-flow: column;
            height: 100%;
            padding: 0px 39px 30px;
            border-radius: 18px;
            box-shadow: 0px 0px 10px #79797929;
            background-color: $white;

            h1 {
                margin-bottom: 10px;
                font-size: $medium;
                font-weight: 500;
                color: $purple-dark;
                text-align: center;
            }

            .content {
                margin-bottom: 20px;

                p {
                    font-size: 14px;
                    color: $purple-dark;
                    text-align: center;
                }
            }

            .btn-wrapper {
                width: fit-content;
                margin: auto auto 0px;

                .action-button {
                    width: 150px;
                    text-align: center;
                    padding: 15px 0px;
                }
            }

            .media {
                display: flex;
                justify-content: center;
                margin: 0 auto 20px;

                &.on-demand-icon-wrapper {
                    width: 119px;
                }

                &.dedicated-teams-icon-wrapper {
                    width: 171px;
                }

                .svg-icon {
                    width: 100%;
                    height: auto;
                    margin-top: -15px;
                }
            }
        }
    }
}
