@import "style-constants.scss";

.application-interview-slots-content-wrapper {
    margin-top: 30.07px;
    padding: 23.32px 40px 30.69px 40px;

    .title {
        font-size: $medium-title;
        font-weight: 500;
        color: $purple-dark;
    }

    .sub-title {
        margin-top: 10px;
        font-size: $medium;
        letter-spacing: 0px;
        color: rgba($purple-dark, 0.8);
    }

    .application-interview-slots-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        list-style-type: none;
        gap: 16px;
        margin: 20px 0px;
    }

    .application-interview-slot-item {
        position: relative;
        display: grid;
        gap: 30px;
        padding: 30px 30px;
        box-shadow: 0px 1px 6px $black-grey-shadow;
        border: 2px solid transparent;
        cursor: pointer;

        .item-row {
            display: flex;
            align-items: center;
        }

        .icon {
            width: 25px;
            height: 25px;
            margin-right: 10px;

            * {
                fill: $purple-dark;
            }
        }

        .item-text {
            font-size: $medium;
            color: $purple-dark;
        }

        &.not-selected {
            opacity: 0.5;
        }

        &.selected {
            border-color: $purple-dark;
            border-radius: 4px;
        }

        &.waiting-for-response {
            cursor: default;
            opacity: 0.7;
        }

        .check-circle-icon {
            position: absolute;
            width: 22px;
            height: 22px;
            top: 15px;
            right: 22px;
            color: $secondary-color-300;
        }
    }

    .interview-slots-action-buttons {
        display: grid;
        grid-auto-flow: column;
        width: fit-content;
        gap: 15px;
        margin: 0 auto;
    }
}
