@import 'style-constants.scss';

.primary-button {
    color: $white;
    background-color: $indigo;
    border-radius: 18px;
    border: 1px solid;
    border-color: transparent;
    padding: 8px 24px;
    letter-spacing: 1.07px;
    line-height: 15px;
    font-weight: 400;
    text-transform: uppercase;
    min-width: 170px;
    
    &.light {
        background-color: $grey-light-background;
        color: $indigo;
        border: 1px solid $indigo;
    }

    &:hover:not(.light) {
        background-color: $white;
        color: $indigo;
        border: 1px solid $indigo;
        
        svg {
            fill: $indigo;
        }
    }
    
    &.white-border {
        border: 1px solid $white;
    }
    
    .MuiButton-label {
        
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        gap: 10px;
    }

    svg {
        fill: $white;
    }

    @media screen and (max-width: $small-desktop) {
        font-size: $tiny;
    }

    // medium desktops
    @media screen and (min-width: calc($small-desktop + 1px)) and (max-width: $medium-desktop) {
        font-size: $smallest;
    }

    // large desktops
    @media screen and (min-width: calc($medium-desktop + 1px)) {
        font-size: $smallest;
    }
}
