@import "style-constants.scss";

.explore-talents-search-filter-wrapper {
    position: sticky;
    position: -webkit-sticky;
    top: 0; /* required */
    z-index: 1000;
    width: 100%;
    margin: auto;
    margin-bottom: 20px;

    .explore-talents-accordion {
        box-shadow: 0px 1px 9px #00000033;

        .MuiAccordionSummary-content {
            display: flex;
            justify-content: center;
            color: #f4b54c;
        }

        .summary {
            .reset-filters-link {
                font-size: 1rem;
                margin-left: 13px;
                &:visited {
                    color: blue;
                }
            }
        }

        .collapse-filters-icon-container {
            display: flex;
            justify-content: center;
        }

        .collapse-filters-icon {
            width: 35px;
            height: 35px;
            color: $primary-color-900;
        }

        .explore-talents-search-filter-container {
            background-color: $white;
            padding-left: 45px;
            width: 100%;

            .explore-talents-search-filter {
                display: flex;

                .main-container {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    padding: 0 30px;

                    .search-container {
                        display: flex;
                        margin-bottom: 20px;

                        .reset-explore-talents-filter-button {
                            border-color: $primary-color-700;
                            color: $primary-color-700;
                            margin-left: 20px;
                        }
                    }

                    .switches-container {
                        display: flex;
                        font-size: 16px;
                        color: $grey-dark;
                        min-height: 33px;

                        .custom-switch {
                            margin-right: 75px;
                        }

                        .vetted-since-container {
                            display: flex;
                            align-items: center;

                            .vetted-since-label {
                                margin-right: 14.71px;
                            }
                        }
                    }

                    .sub-filters-container {
                        display: flex;
                        justify-content: space-between;
                    }

                    .mandatory-nice-to-have-container {
                        padding-top: 30px;
                        display: flex;
                        justify-content: space-between;

                        .project-about-technologies-block {
                            flex: 1;
                            .skill-name-field {
                                width: 255px;
                            }

                            .add-skill-input {
                                background-color: $grey-light;
                            }

                            .sort-list {
                                width: 300px;
                                margin-left: 0px;

                                .skill-box {
                                    padding: 4px 7px;
                                }
                            }
                        }
                    }
                }

                .list-sub-header {
                    padding: 0;
                    font-size: 16px;
                    color: $primary-color-900;
                    font-weight: 500;
                }

                .list-item {
                    &.MuiListItem-root {
                        padding: 0;
                    }

                    .MuiListItemText-primary {
                        font-size: 14px;
                        color: $grey-dark;
                    }
                }
            }
        }
        .hidden {
            opacity: 0;
            pointer-events: none;
        }
    }
}
