@import "style-constants.scss";

.team-member-card {
    min-height: 313px;
    width: calc(50% - 20px);
    background: #f2f3fe;
    border-radius: 4px;
    opacity: 1;
    position: relative;
    overflow: hidden;
    padding: 20px 30px;

    .team-member-header-block {
        border-bottom: 1px solid $primary-color-100;
        padding: 10px;
        display: flex;
        justify-content: space-between;

        .profile-image {
            height: 70px;
            width: 70px;
            box-shadow: 0px 3px 6px #00000029;
            opacity: 1;
        }

        .name-and-position {
            padding-left: 20px;
            align-items: center;

            .team-member-name {
                color: $primary-color-400;
                text-align: left;
                font: normal normal medium 18px/24px Montserrat;
                letter-spacing: 0.12px;
                opacity: 1;
                font-size: 24px;
            }

            .team-member-position {
                text-align: left;
                font: normal normal 300 16px/21px Montserrat;
                letter-spacing: 0.11px;
                color: $grey-light-short-text;
                opacity: 1;
                font-size: 19px;
            }
        }
    }
    .team-member-hobbies,
    .team-member-fun-fact {
        margin-top: 20px;
    }

    .header-block-content {
        display: flex;
    }

    .title {
        color: $primary-color-400;
        font-size: 17px;
        text-align: left;
        font: normal normal medium 16px/21px Montserrat;
        letter-spacing: 0.11px;
        opacity: 1;
    }

    .content {
        color: #7e7e7e;
        font-size: 17px;
        margin-left: 35px;
    }

    .MuiIconButton-root {
        padding: 0;
    }
    .social-icon {
        width: 35px;
        height: 35px;
        margin-right: 10px;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    .linkedIn {
        color: #4975b6;
    }

    .hobbies-wrapper,
    .fun-facts-wrapper {
        display: flex;
        width: 100px;
        justify-content: space-between;
    }

    .left-semicircle {
        content: "";
        padding: 20px;
        border-radius: 100%;
        position: absolute;
        right: 97.5%;
        background: #22d696 0% 0% no-repeat padding-box;
        background: #22d696 0% 0% no-repeat padding-box;
        opacity: 0.5;
    }

    .right-semicircle {
        content: "";
        padding: 30px;
        border-radius: 100%;
        position: absolute;
        left: 96.5%;
        top: 60%;
        background: #5066e7 0% 0% no-repeat padding-box;
        background: #5066e7 0% 0% no-repeat padding-box;
        opacity: 0.5;
    }
}
