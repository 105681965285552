@import 'style-constants.scss';

.previous-button {
    color: $purple-dark;
    background-color: transparent;
    padding: 0px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 14px;
    text-transform: none;
    margin-bottom: 15px;

    &:hover {
        background-color: transparent;
    }

    .MuiButton-label {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        gap: 10px;
    }

    .arrow-icon-wrapper {
        box-shadow: 0px 1px 3px $grey-darker-button, inset 0px 1px 3px $grey-darker-button;
        border: 1px solid $purple-dark;
        border-radius: 4px;
        width: 30px;
        height: 27px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            fill: $purple-dark;
        }
    }

    @media screen and (max-width: $small-desktop) {
        font-size: $tiny;
    }

    // medium desktops
    @media screen and (min-width: calc($small-desktop + 1px)) and (max-width: $medium-desktop) {
        font-size: $small;
    }

    // large desktops
    @media screen and (min-width: calc($medium-desktop + 1px)) {
        font-size: $small;
    }
}
