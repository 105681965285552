$holidays-content-width: 285px;
$header-height: 94px;
$search-bar-height: 44px;
$page-content-width: 717px;
$page-content-width-no-submenu: 930px;
$page-project-details-width: 1150px;
$single-banner-max-width: 1200px;
$horizontal-scroll-appear-width: 1277px;
$side-by-side-banner-max-width: 625px;
$small-dialog-width: 425px;
$candidate-initial-journey-navigation-width: 420px;
$start-candidate-journey-width: 1041px; 

// colors

$app-background: #EFEFEF;

$primary-color-900: #0010ac;
$primary-color-800: #002abe;
$primary-color-700-hover: #0F33B0;
$primary-color-700: #0d37c9;
$primary-color-600: #1e41d5;
$primary-color-500: #2449e1;
$primary-color-400: #5066e7;
$primary-color-300: #7383ec;
$primary-color-200: #9da6f1;
$primary-color-blue-light: #5E8DFA;
$primary-color-blue-lighter: #699DEE;
$blue-creamy: #8EAEFA;
$primary-color-100: #c5c9f6;
$primary-color-70: #EDEFFD;
$primary-color-90: #C5C9F64D;
$primary-color-light: #F3F4FD;
$primary-color-50: #FBD5FF;

$primary-color: $primary-color-700;

$indigo: #5A4BF8;
$indigo-dark: #4438C9;
$indigo-darker: #2D267C;

$secondary-color-900: #006b2e;
$secondary-color-800: #008c44;
$secondary-color-700: #009e50;
$secondary-color-600: #00b05d;
$secondary-color-500: #00c069;
$secondary-color-400: #00cc7e;
$secondary-color-300: #16d696;
$secondary-color-250: #86E6C7;
$secondary-color-200: #7be1b5;
$secondary-color-150: #E7FBF4;
$secondary-color-100: #b3edd2;
$secondary-color-50: #e0f8ed;
$secondary-color-background: #ecfcf6;

$secondary-color: $secondary-color-300;

$teal: #2ACBAE;
$teal-dark: #156657;

$black: #000000;
$grey-progress: #E7E7E7;
$grey-normal-title: #00000099;
$grey-darker-button: #00000033;
$grey-darker-text: #000000DE;
$grey-light-box:#D9D9D9;
$grey-light-short-text: #808495;
$grey-dark: #7E7E7E;
$grey-shadow: #82828224;
$grey-button-hover: #F7F7F7;
$grey-dark-text: #666666;
$grey-darker: #555;
$grey-creamy:#BABABB;
$grey-lighter: #707070;
$grey-cv-box: #F5F5F5;
$grey-light-amount: #CBCBCB;
$grey-light-medium: #E3E3E3;
$grey-light: #F3F3F3;
$grey-disabled: #D1D1D1;
$grey-border: #E3E3E3;
$grey-background: #E6E6E6;
$grey-background-light: #F7F7F9;
$white: #FFFFFF;
$grey-label-text: #818181;
$background-label: #F2F2F2;
$grey-divider:#E5E5E5;
$grey-dashed-border: #A5A5A5;
$grey-progress: #E9E9E9;
$grey-avatar-background: #DBDBDB;

$blue-darkest: #00051D;
$purple-darker: #1D2548;
$purple-darker-light: #2D3351;
$purple-dark: #040227;
$purple-dark-selected: #363552;
$purple-dark-light: #283351;
$purple-dark-lighter: #273351;
$purple-light: #E8EAFC;
$purple-light-nav: #C5C9F6;
$medium-shade-blue: #363452; 
$medium-light-shade-blue: #353451;
$medium-dark-shade-blue: #343D63;
$light-shade-blue:#EEEFFC;
$black-grey-shadow: #00000029;
$grey-medium-shadow: #79797929;
$grey-light-background: #EFEFEF;

$actions-green: #22d696;

$actions-background: #FAFAFA;
$actions-border: #DDDDDD;

$red: #FF0000;
$red-dark: #D21919;
$red-darker: #DC083A;
$red-creamy: #E87D97;
$red-light: #FBE6EB;
$red-light-darker: #F7D7DF;
$red-pink: #FBCCD8;
$orange: #F09A0A;
$orange-light: #FDF5E6;
$pink: #FFABAB;
$pink-light: #E9A4B3;
$pink-dark: #EE69E5;
$yellow: #FAC800;
$yellow-light: #FFDD00;
$green-light: #16D696;
$blue-light: #1649D6;
$purple: #B516D6;

$active-holidays-green: #2ACBAE;
$upcoming-holidays-purple: #8E94F3;
$sick-leaves-grey: $grey-light-background;

$flag-awaiting-approval: #FFDA83;
$flag-missing-account-manager: #FF8373;

// shell widths
$content-shell-width-without-submenu-large-desktop: 1430px;
$content-shell-width-with-submenu-large-desktop: 1200px;
$content-shell-width-without-submenu-medium-desktop: 1100px;
$content-shell-width-with-submenu-medium-desktop: 900px;
$content-shell-width-without-submenu-small-desktop: 900px;
$content-shell-width-with-submenu-small-desktop: 750px;

// menu widths
$navigation-width: 208px;
$navigation-width-closed: 90px;
$submenu-width: 208px;

// candidate journey style constants
$paper-element-sm: 480px;
$paper-element-m: 710px;
$paper-element-m-nopadding: 675px;
$paper-element-m-plus: 750px;
$paper-element-l: 950px;

$candidate-journey-desktop: '@media(min-width 1000px)';

// coaching opportunity constants
$coaching-session-card-width: 455px;
$coaching-session-card-width-thin: 416px;

// upcoming birthdays paper widths
$upcoming-birthdays-paper-width-large-desktop: 686px;
$upcoming-birthdays-paper-width-medium-desktop: 528px;
$upcoming-birthdays-paper-width-small-desktop: 432px;

// text size constants
$extra-large: 30px;
$larger-title: 24px;
$large-title-extra: 22px;
$large-title: 20px;
$medium-title: 18px;
$medium: 16px;
$small: 14px;
$smaller: 13px;
$smallest: 12px;
$tiny: 10px;
$tinier: 9px;
$tiniest: 8px;

// responsive breakpoints 
$large-desktop: 1920px;
$medium-desktop: 1657px;
$small-desktop: 1366px;
$tablet: 1024px;
$tablet-nav: 1100px;
$desktop: 1100px;
$mobile: 1100px;
$mobile-nav: 600px;

@mixin profile-creation-mobile {
    @media (max-width: 790px) {
      @content;
    }
}

:export {
    appBackground: $app-background;
    primaryColor: $primary-color;
    greyDark: $grey-dark;
    greyDarkText: $grey-dark-text;
    actionGreen: $actions-green;
    redDarker: $red-darker;
    orange: $orange;
    pinkLight: $pink-light;
    smallDesktop: $small-desktop;
    desktop: $desktop;
    yellowLight: $yellow-light;
    greenLight: $green-light;
    blueLight: $blue-light;
    purple: $purple;
}
