@import 'style-constants.scss';

.action-button {
    border-radius: 30px;
    box-shadow: 4px 5px 5px #0000000D;
    padding: 11px;
    font-size: $larger-title;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0.23px;
    color: $white;
    background-color: $teal;

    &:hover {
        background-color: $teal;
    }

    &.with-icon {
        font-size: $medium;
        letter-spacing: 0.15px;

        .MuiButton-label {
            display: grid;
            grid-auto-flow: column;
            gap: 24px;
        }

        .icon {
            width: 25px;
            height: 25px;
        }
    }

    &.inverted {
        color: $grey-lighter;
        background-color: $white;
        border: 2px solid $teal;
    }

    @include profile-creation-mobile {
        font-size: $smaller;
        line-height: 16px;
        letter-spacing: 0.12px;

        &.with-icon {
            padding-top: 8px;
            padding-bottom: 8px;
            font-size: $tinier;
            letter-spacing: 0.09px;

            .MuiButton-label {
                gap: 12px;
            }

            .icon {
                width: 20px;
                height: 20px;
            }
        }

        &.inverted {
            border-width: 1px;
        }
    }
}