@import 'style-constants.scss';

.my-applications-list-wrapper {
    .my-applications-list {
        padding-top: 10px;
        display: grid;
        gap: 20px;
    }

    .my-application-card-link {
        text-decoration: none;
    }

    .my-application-card-wrapper {
        position: relative;
        background: $white 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px $grey-darker-button;
        border-radius: 18px;
        padding: 33px 42px 30px;

        .application-status-label {
            position: absolute;
            right: 0;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: auto;
            height: 33px;
            padding: 15px;
            border-bottom-left-radius: 4px;
            border-top-right-radius: 4px;
            text-align: center;
            font-size: $medium;
            line-height: 17px;
            font-weight: 400;
            letter-spacing: 0.017em;

            &.pending-review,
            &.pending-admin-review,
            &.interview-stage,
            &.not-vetted {
                background-color: rgba($grey-dark, 0.1);
                color: $grey-dark;
            }

            &.hired {
                background-color: rgba($secondary-color-300, 0.1);
                color: $secondary-color-300;
            }

            &.rejected {
                background-color: rgba($red-darker, 0.1);
                color: $red-darker;
            }
        }

        .position-date-wrapper {
            width: 100%;
            display: grid;
            align-items: center;
            grid-auto-flow: column;
            grid-template-columns: repeat(2, 1fr);
            margin-bottom: 7px;

            .position {
                font-size: $medium-title;
                line-height: 22px;
                font-weight: 500;
                letter-spacing: 0.61px;
                color: $purple-dark;
            }

            .date-wrapper {
                width: fit-content;
                display: grid;
                grid-auto-flow: column;
                align-items: center;
                gap: 7.55px;
                font-size: $medium;
                line-height: 19px;
                font-weight: 400;
                letter-spacing: 0.15px;
                color: $medium-dark-shade-blue;
            }
        }

        .company {
            font-size: $medium;
            line-height: 21px;
            font-weight: 400;
            letter-spacing: 0.28px;
            color: $purple-dark;
        }

        .section-divider {
            margin: 20px 0px;
            background-color: $grey-light-amount;
        }

        .feedback-wrapper {
            .feedback-header {
                width: fit-content;
                display: grid;
                grid-auto-flow: column;
                align-items: center;
                gap: 10px;
                margin-bottom: 8px;

                h4 {
                    font-size: $medium;
                    font-weight: 500;
                    line-height: 21px;
                    letter-spacing: 0.28px;
                    color: $purple-dark;
                }
            }

            .feedback-body {
                font-size: $medium;
                line-height: 21px;
                letter-spacing: 0.28px;
                color: $purple-dark;
                margin-left: 23.1px;
                white-space: pre-wrap;
                word-wrap: break-word;
            }
        }

        .application-respond-button {
            display: flex;
            margin: 20px auto 0;
        }
    }

    .no-applications-found {
        display: grid;
        justify-content: center;
        padding-top: 20px;

        .no-applications-apply-btn {
            width: fit-content;
            margin: 20px auto 0;
        }
    }
}
