@import 'style-constants.scss';

.secondary-button {
    color: $indigo;
    background-color: $white;
    border-radius: 18px;
    border-color: $indigo;
    padding: 8px 24px;
    font-weight: 400;
    letter-spacing: 1.07px;
    line-height: 16px;
    text-transform: uppercase;
    min-width: 170px;

    &:hover {
        background-color: $white;
    }

    &.discard,
    &.delete {
        color: $white;
        background-color: $red-darker;
        border: none;
    }

    &.save {
        color: $white;
        background-color: $teal;
        border: none;
    }

    .MuiButton-label {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        gap: 10px;
    }

    svg {
        fill: $purple-dark;
    }

    @media screen and (max-width: $small-desktop) {
        font-size: $tiny;
    }

    // medium desktops
    @media screen and (min-width: calc($small-desktop + 1px)) and (max-width: $medium-desktop) {
        font-size: $smallest;
    }

    // large desktops
    @media screen and (min-width: calc($medium-desktop + 1px)) {
        font-size: $smallest;
    }
}
