@import "style-constants.scss";

.start-candidate-journey-desktop-page {
    display: flex;
    overflow: auto;
    width: 100%;
    height: 100vh;

    @media screen and (min-width: $desktop) {
        background: url('/assets/start-journey-background.svg') no-repeat center center fixed;
        background-size: cover;
    }

    .paper-content-centered {
        margin: auto;
    }

    .start-candidate-journey-wrapper {
        margin: 66px auto;
        height: fit-content;
        width: $start-candidate-journey-width;
        
        .company-logo-wrapper {
            margin: 0 auto;
        }

        .start-candidate-journey-container {
            display: grid;
            gap: 33px;
            margin-bottom: 15px;
        }

        .company-logo-wrapper .company-logo {
            max-width: 100%;
            height: 45px;
        }

        .start-candidate-journey-content {
            background-color: $white;
            border-radius: 4px;
            box-shadow: 0px 0px 10px #79797929;
            padding: 30px 40px;
            display: flex;
            margin: auto;
            flex-direction: column;
            width: 664px;
        }

        .start-candidate-journey-header {
            .paper-element-title {
                font-size: 25px;
                font-weight: 700;
                letter-spacing: 0.84px;
                color: $purple-dark;
            }
        }

        .start-candidate-journey-sub-header {
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0px;
            color: $purple-dark;
        }

        .start-candidate-journey-steps-container {
            padding-top: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .single-step {
            display: flex;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0px;
            color: $purple-dark;

            &:not(:last-child) {
                margin-bottom: 30px;
            }

            .common-line {
                display: flex;
                margin-bottom: 10px;

                .step-title {
                    margin-right: 10px;
                }
            }

            .step-box {
                box-shadow: 0px 1px 3px $grey-darker-button;
                border-radius: 4px;
                background-color: $purple-dark;
                display: flex;
                margin-right: 14px;
                width: 30px;
                height: 32px;

                .box-text {
                    color: $white;
                    margin: auto;
                }
            }

            .step-duration {
                font-weight: 300;
            }

            .step-description {
                font-weight: 400;
            }
        }

        .highlighted-text {
            &.green {
                color: $secondary-color-300;
            }
        }

        .thank-you-for-joining-sub-header {
            color: $purple-dark;
            text-align: left;
            font: $medium Montserrat;
            letter-spacing: 0.11px;
            line-height: 26px;
            opacity: 1;

            &.margin-bottom {
                margin: 0 0 20px 0;
            }
        }
    
        .start-candidate-journey-button {
            display: flex;
            margin: 30px auto auto;
        }
    }
}
