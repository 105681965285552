@import "style-constants.scss";

.skills-wrapper {
    .skills-container {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: 20px;

        .skill-box {
            font-size: 14px;
            letter-spacing: 0.25px;
            border: 1px solid $grey-light-amount;
            border-radius: 5px;
            padding: 7px 14px 6px;
            margin-right: 20px;
            margin-bottom: 9px;
            max-width: 300px;
            word-break: break-all;
            color: $grey-dark-text;
        }
    }
}
