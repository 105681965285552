@import 'style-constants.scss';

.coaching-opportunity-card-wrapper {
    display: flex;
    padding: 30.65px 40.54px;
    margin-bottom: 20px;

    .MuiAvatar-root {
        width: 52px;
        height: 52px;
    }

    .coaching-opportunity-info {
        width: 100%;
        margin-left: 20px;

        &:hover {
            cursor: pointer;
        }

        .title {
            color: $purple-dark;
            text-align: left;
            font: normal normal bold $medium Montserrat;
            letter-spacing: 0.54px;
            opacity: 1;
            padding-bottom: 20px;
            word-break: break-word;
        }

        .description {
            padding-bottom: 21px;
            color: $purple-dark;
            font-size: $medium;
            letter-spacing: 0.54px;
            opacity: 1;
            word-break: break-word;
        }

        hr {
            margin-bottom: 20px;
            border: 1px solid $grey-light-amount;
        }

        .actions {
            flex-grow: 3;
            display: flex;
            margin-left: 10px;
            align-items: center;
            width: 120px;
            justify-content: flex-end;
            height: fit-content;

            .three-dots-action {
                margin-left: 20px;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .info-header {
            display: flex;
            justify-content: space-between;
        }
    }

    .flex-items {
        display: flex;
        align-items: baseline;
    }

    .languages-list {
        display: flex;
        flex-wrap: wrap;
        margin-right: -5px;
        margin-bottom: -5px;
        margin-left: 15px;

        .language-item {
            padding: 5px 12px;
            margin-right: 5px;
            margin-bottom: 5px;
            border-radius: 4px;
            background-color: $primary-color-light;
            color: $primary-color-700;
            font-size: $small;
            font-weight: 500;
            letter-spacing: 0.24px;
            height: fit-content;
        }
    }
}
