@import "style-constants.scss";

.options-card-wrapper {
    padding: 30px 40px;
    background-color: $white;
    box-shadow: 0px 0px 10px #79797929;
    border-radius: 4px;

    .paper-content-flex {
        display: grid;
        gap: 21px;
    }

    .options-card-title {
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.61px;
        color: $purple-dark;
    }

    .options-card-sub-title {
        font-size: 16px;
        letter-spacing: 0;
        color: $purple-dark;
    }

    .options-card-labels-wrapper {
        display: grid;
        gap: 25px;

        &.transition-error {
            .options-card-label {
                outline: 1px solid $red-darker;
                color: $red-darker;
            }

            .radio-btn {
                color: $red-darker;
            }
        }

        &.error,
        &.animation-error {
            .options-card-label {
                animation: fadeout 3s ease-in-out forwards;
            }

            .radio-btn {
                animation: fadeout-radio-btn 3s ease-in-out forwards;
            }
        }
    }

    .options-card-label {
        box-shadow: 0px 0px 10px #79797929;
        border-radius: 4px;
        justify-content: space-between;
        margin: 0;
        padding: 20px 40px;
        color: $purple-dark;
        font-size: 16px;
        letter-spacing: 0px;

        .radio-btn {
            padding: 0;
            color: $grey-light-short-text;

            &.Mui-checked,
            &.Mui-checked:hover {
                color: $secondary-color-300;
                background-color: rgba($secondary-color-300, 0.04);
            }

            &:hover {
                background-color: rgba($grey-light-short-text, 0.04);
            }
        }
    }

    .invalid-field-wrapper {
        display: flex;
        align-items: center;
        color: $red-darker;
        padding-top: 11px;

        .error-icon {
            width: 22px;
            height: 22px;
            margin-right: 9px;
        }

        span {
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.25px;
        }
    }
}

@keyframes fadeout {
    from {
        color: $red-darker;
        outline: 1px solid $red-darker;
    }
    to {
        color: $purple-dark;
        outline: 1px solid transparent;
    }
}

@keyframes fadeout-radio-btn {
    from {
        color: $red-darker;
    }
    to {
        color: $grey-light-short-text;
    }
}
