@import "style-constants.scss";

.candidate-status-wrapper {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 10px 20px;
    color: $grey-dark;
    background-color: $background-label;
    border-radius: 0 4px 0 4px;
    font-size: $medium;

    .feedback-box {
        position: absolute;
        height: fit-content;
        right: 0.5rem;
        top: 100%;
        width: 18rem;
        border-radius: 18px;
        box-shadow: 0px 1px 3px #82828224;
        opacity: 1;
        z-index: 999;

        .feedback {
            
            text-align: left;
            font-size: $small;
            letter-spacing: 0.25px;
            color: $white;
            opacity: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            white-space: -moz-pre-wrap;
            white-space: -pre-wrap;
            white-space: -o-pre-wrap;
            white-space: pre-wrap;
            word-wrap: break-word;
            br {
                margin-bottom: 5px;
            }
        }
    }

    .status-icon {
        &:hover {
            cursor: context-menu;
        }

        &.rejected {
            background-color: $red-light;
            color: $red-darker;
        }
    }

    &.rejected {
        background-color: $red-light;
        color: $red-darker;
    }
}
