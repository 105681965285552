@import "style-constants.scss";

.start-candidate-journey-desktop-page {
    .start-candidate-journey-content {
        .candidate-job-loader {
            margin: auto;
        }

        .seen-job-container {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            padding: 30px 40px;
            margin: 0 0 20px 0;
            min-height: 121px;

            .job-company-information {
                display: grid;
                grid-auto-flow: column;
                gap: 10px;
                align-items: center;
                width: fit-content;
                margin-bottom: 10px;

                h3 {
                    text-align: left;
                    font: normal normal bold $medium Montserrat;
                    letter-spacing: 0.21px;
                    color: $purple-dark;
                    opacity: 1;
                    line-height: 30px;
                }
            }

            .job-position {
                display: grid;
                gap: 10px;
                flex-grow: 1;
                margin-bottom: 14px;

                p {
                    text-align: left;
                    font: normal normal normal $medium Montserrat;
                    letter-spacing: 0.28px;
                    color: $purple-dark;
                    opacity: 1;
                    line-height: 21px;
                }
            }

            .job-logo {
                height: 53px;
                width: auto;
            }

            .job-location {
                display: flex;
                justify-content: space-between;
                align-self: center;

                p {
                    margin: 0 11px;
                    text-align: left;
                    align-self: center;
                    font: normal normal normal $medium Montserrat;
                    letter-spacing: 0.28px;
                    color: $purple-dark;
                    opacity: 1;
                }
            }
        }
    }
}