@import 'style-constants.scss';

.book-interview-dialog {
    display: flex;
    justify-content: space-between;

    .book-interview-container {
        overflow-y: auto;
        overflow-x: hidden;
        padding-left: 10px;
        padding-right: 0;
        position: relative;

        &.full-size {
            height: 660px;
            min-width: 1000px;
        }

        &.full-size-mobile {
            height: 85vh;
        }
    }

    .book-interview-info {
        margin-right: 20px;
        flex: 1;
        max-width: 220px;
        color: $grey-dark-text;
        box-shadow: 0px 1px 10px #00000024;
        padding: 30px 36px;
        height: 640px;

        .calendly-avatar-image,
        .default-avatar {
            width: 61px;
            height: 61px;
            margin-bottom: 5px;
        }

        .calendly-avatar-image {
            border-radius: 50%;
        }

        .calendly-user-name {
            font-size: 16px;
            line-height: 28px;
            letter-spacing: 0px;
        }

        .calendly-interview-duration {
            margin-top: 23px;
            font-size: 16px;
            line-height: 28px;
            display: flex;
            align-items: center;

            .clock-icon {
                margin-right: 7px;
            }
        }
    }

    .dialog-title {
        margin-bottom: -20px;
        padding-bottom: 0;
        padding-right: 8px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .dialog-title-text-container {
        width: 46.5%;
        display: flex;
        justify-content: space-between;
    }

    .dialog-title-text {
        color: $grey-dark;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.15px;
    }

    .dialog-close-btn {
        margin-right: 20px;

        &.mobile {
            margin-right: 0px;
            margin-bottom: 10px;
        }
    }

    .email-field {
        width: 798px;
        margin: auto;
    }
}
