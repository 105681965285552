@import "style-constants.scss";

.cv-skills-wrapper {
    .title {
        margin-right: 10px;
        margin-bottom: 10px;
        color: $purple-dark;
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 0.21px;
        text-transform: uppercase;
    }

    .skills-container {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: 20px;
        margin-bottom: -16px;

        .skill-box {
            font-size: 15px;
            letter-spacing: 0.2px;
            border: 1px solid #DDDDDD;
            border-radius: 4px;
            padding: 7px 14px;
            max-width: fit-content;
            color: $blue-darkest;
            margin-right: 12px;
            margin-bottom: 16px;
        }
    }

    .header {
        display: flex;
        border-bottom: 1px $purple-dark solid;

        .item-edit-icon-wrapper {
            display: none;
            align-items: center;
            justify-content: center;
            width: 22px;
            height: 22px;
            color: $grey-dark;
            background-color: $grey-light;
            border: 1px solid $grey-light;
            border-radius: 14px;
            cursor: pointer;
            margin-right: 7px;

            .item-edit-icon {
                width: 10px;
                height: 10px;
            }

            &:hover {
                border-color: $purple-dark;
                .item-edit-icon  {
                    color: $purple-dark;
                }
            }
        }
    }

    &:hover {
        .item-edit-icon-wrapper {
            display: flex;
        }
    }
}
