@import "style-constants.scss";

.nav-main-list {
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
        width: 0px;
    }

    .notification-badge {
        position: absolute;
        top: 15px;
        left: 45px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: $red-darker;
        display: flex;
        align-items: center;
        justify-content: center;

        .badge-text {
            font-size: 10px;
            line-height: 1;
            color: $white;
        }
    }

    @media screen and (max-width: $mobile) {
        .notification-badge {
            position: absolute;
            top: 9px;
            right: 75%;
            left: unset;
        }
    }
}
