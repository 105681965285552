@import "style-constants.scss";

.client-dashboard-box-wrapper {
    padding: 40px 2px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $white;
    box-shadow: 0px 1px 3px #cccccc80;
    border-radius: 18px;

    &.active-box {
        padding: 36px 0px;
        border: 2px solid $purple-dark;
    }

    .box-count-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            font-size: 70px;
            font-weight: 900;
            line-height: 65px;
            letter-spacing: 0.9px;
        }

        &.active span {
            color: $primary-color-blue-light;
        }

        &.past span {
            color: $grey-light-amount;
        }

        &.contract span {
            color: $orange;
        }

        &.full-time span {
            color: $secondary-color-300;
        }
    }

    .box-text-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            font-size: 20px;
            letter-spacing: 0.35px;
            color: $purple-dark;
        }
    }
}

.client-dashboard-box-wrapper.clickable:hover {
    background: $white 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 10px #CCCCCC;
    border-radius: 18px;
    opacity: 1;
    cursor: pointer;

    &.active-box {
        padding: 36px 0px;
        border: 2px solid $purple-dark;
        box-shadow: none;
    }
}
