@import "style-constants.scss";

.role-selection-wrapper {
    .role-selection-heading {
        font-size: $large-title;
        font-weight: 700;
        color: $purple-dark;
        letter-spacing: 0.19px;
        text-transform: uppercase;
    }

    form {
        padding: 0px 40px;
    }
    .options-labels-wrapper {
        display: grid;
        gap: 20px;
        padding-top: 55px;
        padding-bottom: 18px;

        .invalid-field-message {
            margin-top: -15px;
        }

        .options-label {
            margin: 0;
            justify-content: space-between;
            padding: 12px 30px;
            border-radius: 4px;
            background-color: $white;

            span {
                font-size: $medium;
                letter-spacing: 0px;
                text-transform: uppercase;
                color: $purple-dark;
            }

            &.checked {
                box-shadow: 0px 0px 10px $grey-medium-shadow;

                span {
                    font-weight: 500;
                }
            }

            .radio-btn {
                color: $grey-light-short-text;

                .radio-btn-dot {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 18px;
                    height: 18px;
                    outline: 2px solid $grey-light-short-text;
                    border-radius: 100%;
                }

                &.Mui-checked {
                    color: $secondary-color-300;

                    .radio-btn-dot {
                        outline-color: $secondary-color-300;

                        &::after {
                            content: ' ';
                            position: absolute;
                            top: 2px;
                            width: 14px;
                            height: 14px;
                            background: $secondary-color-300;
                            border-radius: 100%;
                        }
                    }

                    &:hover {
                        background-color: rgba($secondary-color-300, 0.04);
                    }
                }

                &:hover {
                    background-color: rgba($grey-light-short-text, 0.04);
                }
            }
        }
    }

    .terms-and-conditions-checkbox-wrapper {
        margin-left: -12px;
        margin-bottom: -12px;

        .invalid-field-message {
            margin-top: -7px;
            padding-left: 12px;
        }

        .terms-and-conditions-label {
            .MuiCheckbox-colorPrimary.Mui-checked {
                color: $secondary-color-300;
            }
    
            a {
                color: $primary-color-700;
            }
        }
    }
}
