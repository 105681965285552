@import "style-constants.scss";

.select-a-role-layout-wrapper {
    display: flex;
    flex-direction: row;
    height: 100vh;
    overflow: auto;

    .select-a-role-layout-body-wrapper {
        width: 100%;
        background: url('../../assets/clientOnboarding/onboarding-background-default.svg') no-repeat center center fixed;
        background-size: cover;
    }

    .select-a-role-layout-content {
        position: relative;
        height: 100vh;
        overflow: auto;
    }
}
